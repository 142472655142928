@keyframes load {
  0% {
    background: var(--color-primario);
    margin-top: 25%;
    height: 10%;
  }
  50% {
    background: var(--color-secundario);
    height: 100%;
    margin-top: 0%;
  }
  100% {
    background: var(--color-primario);
    height: 10%;
    margin-top: 25%;
  }
}

.loader {
  position: relative;
  margin: auto;
  width: 175px;
  height: 100px;
  z-index: 9999;
}

.loader span {
  display: block;
  background: var(--color-primario);
  width: 7px;
  height: 10%;
  border-radius: 14px;
  margin-right: 5px;
  float: left;
  margin-top: 25%;
}

.loader span:last-child {
  margin-right: 0;
}

.loader span:nth-child(1) {
  animation: load 2.5s 1.4s infinite linear;
}

.loader span:nth-child(2) {
  animation: load 2.5s 1.2s infinite linear;
}

.loader span:nth-child(3) {
  animation: load 2.5s 1s infinite linear;
}

.loader span:nth-child(4) {
  animation: load 2.5s 0.8s infinite linear;
}

.loader span:nth-child(5) {
  animation: load 2.5s 0.6s infinite linear;
}

.loader span:nth-child(6) {
  animation: load 2.5s 0.4s infinite linear;
}

.loader span:nth-child(7) {
  animation: load 2.5s 0.2s infinite linear;
}

.loader span:nth-child(8) {
  animation: load 2.5s 0s infinite linear;
}

.loader span:nth-child(9) {
  animation: load 2.5s 1.4s infinite linear;
}

.loader span:nth-child(10) {
  animation: load 2.5s 1.2s infinite linear;
}

.loader span:nth-child(11) {
  animation: load 2.5s 1s infinite linear;
}

.loader span:nth-child(12) {
  animation: load 2.5s 0.8s infinite linear;
}

.loader span:nth-child(13) {
  animation: load 2.5s 0.6s infinite linear;
}

.loader span:nth-child(14) {
  animation: load 2.5s 0.4s infinite linear;
}

.loader span:nth-child(15) {
  animation: load 2.5s 1.4s infinite linear;
}

.message {
  position: absolute; /* Posición absoluta respecto al contenedor relativo */
  width: 100%; /* Asume el ancho completo del contenedor */
  top: 120%; /* Posición debajo del loader, ajusta según sea necesario */
  left: 0; /* Alinea a la izquierda del contenedor */
  font-size: 1.2rem; /* Tamaño de la fuente, ajusta según sea necesario */
  color: var(--color-primario); /* Color de la fuente */
  text-align: center; /* Centra el texto horizontalmente */
  white-space: nowrap; /* Evita que el texto se envuelva */
display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 9999;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.fadeOut {
  opacity: 0;
}

.fadeIn {
  opacity: 1;
}

@media (max-width: 768px) {
  .message{
    display: none;
  }

}