:root {
  --color-primario: #0B1D51;  
  --color-secundario: #48baa5;
  --color-terciario: #b2b2b2;
  --border-radius: 10px;
  --sombreado: 1px 1px 10px 0px rgba(, 0, 0, 0.25);
  --color-white: #ffffff;
  --color-black: #000000;
  --text-color: #fff;
}

html {
  font-family: "Inter", sans-serif;
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #ffff;
}

.main {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

.central {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 4rem;
  padding: 4rem 3rem;
  position: relative;
}

.logo{
  width: 30vh;
  position: absolute;
  top: 0;
  left: 2;
}
.logo:hover{
  cursor: pointer;
}

.btnPrincipal {
  width: 100%;
  background-color: var(--color-primario);
  border-radius: var(--border-radius);
  border: none;
  padding: 1rem 1.5rem;
  color: white;
  font-weight: 600;
  box-shadow: var(--sombreado);
  transition:
    background-color 0.3s,
    transform 0.3s;
  margin: 2rem 0;
}

.btnPrincipal:hover,
.btnSecundario:hover {
  cursor: pointer;
  background-color: var(--color-primario);
}

.btnPrincipal:focus,
.btnSecundario:focus,
.btnPrincipal:focus-visible,
.btnSecundario:focus-visible {
  outline: none;
}

.btnSecundario {
  width: 100%;
  background-color: var(--color-secundario);
  border-radius: var(--border-radius);
  border: none;
  padding: 15px 20px;
  color: white;
  font-weight: 600;
  box-shadow: var(--sombreado);
  transition:
    background-color 0.3s,
    transform 0.3s;
  margin: 2rem 0;
}

.btnSecundario:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btnSecundario:disabled:hover {
  background-color: var(--color-secundario);
}

.btnSecundario a{
  color: white;
  text-decoration: none;
  
}

.rightSection, .leftSection, .rightSectionLoader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.rightSectionIa{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.leftSectionIA{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.textArea {
  width: 100%;
  height: 150px;
  border-radius: var(--border-radius);
  border: 1px solid #ccc;
  font-size: 1rem;
}

.formularioStepOne h1, .formularioStepTwo p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 200;
  color: var(--color-primario);
}

.formularioStepOne h2 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--color-primario);
}

.formularioStepOneIA h1, .formularioStepTwo p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 200;
  color: var(--color-primario);
}

.formularioStepOneIA h2 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--color-primario);
}

.textAreaStepOne{
  width: 95%;
  height: 30vh;
  border-radius:30px;
  border: none;
  font-size: 14px;
  resize: none;
  padding: 20px;
  color: var(--color-primario);
  outline: none;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.20);
  font-family: "Inter", sans-serif;
  line-height: 22px;
  animation: fadeIn 1s ease;
}

.textAreaStepOne::placeholder{
  color: rgba(0, 0, 0, 0.238);
  font-size: 0.9rem;
}

.textAreaStepOneRespuesta{
  width: 95%;
  height: 30vh;
  border-radius:30px;
  border: none;
  font-size: 14px;
  resize: none;
  padding: 20px;
  color: var(--color-primario);
  outline: none;
  box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.20);
  font-family: "Inter", sans-serif;
  line-height: 22px;
  background-color: #fff;
}


.formularioStepTwo h2 {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--color-primario);
}

.icon {
  width: 40px;
  margin-right: 10px;
}

.rightSection h2{
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 200;
  color: var(--color-primario);
}

.benefits{
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.benefit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  opacity: 0; /* Inicialmente invisible */
  animation: slideIn 0.8s ease forwards;
}

/* Añadir un retardo en la animación para cada beneficio */
.benefit:nth-child(1) {
  animation-delay: 0.2s;
}
.benefit:nth-child(2) {
  animation-delay: 0.4s;
}
.benefit:nth-child(3) {
  animation-delay: 0.6s;
}
.benefit:nth-child(4) {
  animation-delay: 0.8s;
}

.benefit p{
  color: var(--color-primario);
}

.benefitsListTitleSVG{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.benefitsListTitleSVG svg{
  width: 35px;
  height: 35px;
  fill: var(--color-secundario);
}

.benefitsListTitleSVG h4{
  font-size: 20px;
  font-weight: 200;
  color: var(--color-primario);
}

.formularioStepTwo {
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.formularioStepTwo label {
  color: gray;
  font-size: .8rem;
  margin: .5rem 0;
}
.contenedorLogo:hover{
  cursor: pointer;
}
.formularioStepTwo input {
  border: none;
  border-radius: var(--border-radius);
  width: 100%;
  height: 40px;
  margin: 5px 0;
  padding: 0 1rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
  text-align: left;
}

.formularioStepTwo input::placeholder {
  color: rgba(0, 0, 0, 0.238);
  font-size: 0.8rem;
}

.formularioStepTwo input:focus {
  outline: none;
}
.loaderContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50%;
}

.analisisIA {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-primario);
  color: white;
  height: 80%;
  border-radius: var(--border-radius);
  padding: 2rem;
}

.analisisIA hr{
  border: 0.1px solid white;
  width: 100%;
}
.analisisIATexto {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.text1,
.text2,
.text3,
.text4 {
  display: flex;
  align-items: center;
  width: 100%;
  user-select: none;
  font-size: 0.9rem;
  line-height: 1.1rem;
}
.text1,
.text3,
.text4 {
  padding: 0 1rem;
}

.analisisIATexto .text1,
.analisisIATexto .text2,
.analisisIATexto .text3,
.analisisIATexto .text4 {
  opacity: 0; /* Inicialmente invisible */
  animation: slideIn 0.8s ease forwards;
}

/* Retardo en la animación para secuencia */
.analisisIATexto .text1 {
  animation-delay: 0.2s;
}

.analisisIATexto .text2 {
  animation-delay: 0.4s;
}

.analisisIATexto .text3 {
  animation-delay: 0.6s;
}

.analisisIATexto .text4 {
  animation-delay: 0.8s;
}

.text1 p {
  width: 95%;
}


.text2 {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: 2.5rem 1rem;
  width: 95%;
}

.text2 p {
  color: var(--color-secundario) !important;
  width: 100%;
}

.text3 p,
.text4 p {
  width: 85%;
}

.svgIA {
  width: 35px;
  height: 35px;
  fill: var(--color-white);
  margin-right: 1rem;
  
}

.svgIA2 {
  width: 35px;
  height: 35px;
  fill: var(--color-secundario);
  margin-right: 1rem;
}

.text1 svg {
  fill: #eb5757 !important;
}

.phil{
  display: none;
}

.containersvg {
  cursor: pointer;
}

.containersvg input {
  display: none;
}

.containersvg svg {
  overflow: visible;
  width: 20px;
  margin-right: 0.5rem;
}

.pathsvg {
  fill: none;
  stroke: var(--color-primario);
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition:
    stroke-dasharray 0.5s ease,
    stroke-dashoffset 0.5s ease;
  stroke-dasharray: 241 9999999;
  stroke-dashoffset: 0;
}

.containersvg input:checked ~ svg .pathsvg {
  stroke-dasharray: 70.5096664428711 9999999;
  stroke-dashoffset: -262.2723388671875;
}
.analisisMovil{
  display: none;
}
.analisisOrdenador{
  font-size: 1.4rem;
  font-weight: 300;
  color: white;
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  html{
    background-color: #48baa55b;
  }

  .main{
    height: 100vh;
    overflow-y: scroll;
  }

  .central{
    padding: 2rem 0 0 0;
    height: 100%;
  }

  .logo{
    width: 20vh;
    top: 1.5rem;
    padding: 0 2rem;
  }

  .rightSection{
    display: none;
  }

.btnSecundario{
  position: fixed;
  bottom: 0;
  width: 90%;
  box-shadow: none;
  left: 50%;
  transform: translateX(-50%);
}

.textos {
  padding: 0 2rem;
  margin-top: 1rem;
}

.formularioStepOne{
  height: 100%;
}

  .textareamovil {
    width: 100%;
    height: 70%;
    margin-top: 5rem;
    position: relative;
}

  .textAreaStepOne {
    padding: 4rem 2rem 0 2rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    resize: none;
    overflow-y: auto;
    box-shadow: none;
    font-size: 1.25rem;
    border-radius: 30px 30px 0 0;
    animation: fadeIn 1s ease;
}

/* Placeholder */
.textAreaStepOne::placeholder {
    font-size: 1rem;
}

.formularioStepOne .textos, .formularioStepOne .textareamovil {
  opacity: 0; /* Inicialmente invisible */
  animation: slideIn 0.8s ease forwards;
}

/* Añadir retraso para que los elementos aparezcan en secuencia */
.formularioStepOne .textos {
  animation-delay: 0.2s;
}

.formularioStepOne .textareamovil {
  animation-delay: 0.4s;
}

.phil{
    display: block;
    width: 5rem;
    position: absolute;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: 1s; 
}

  .formularioStepTwo {
    padding: 0 2rem;
    margin-top: 1rem;
  }

  .formularioStepTwo p{
    font-size: 1.25rem;
  }

  .formularioStepTwo label {
    margin: 0;
  }

  .formularioStepTwo input {
    margin: 1rem 0;
    font-size: 1rem;
  }

  .formularioStepOneIA{
    display: none;
  }

  .leftSectionIA, .formularioStepOneIA{
    display: none;
  }

  .rightSectionIa{
    height: 100%;
    margin-top: 20%;
  }

  .analisisIA{
    background-color: transparent;
    padding: 0 1rem;
  }

  .analisisOrdenador{
    display: none;
  }
  .analisisMovil{
    display: flex;
    font-size: 1.2rem;
    font-weight: 300;
    color: var(--color-primario);
  }

  .analisIALinea hr{
    border: 0.1px solid var(--color-primario);
  }

  .analisisIATexto{
    width: 90%;
    gap: 1rem;
    margin-top: 1rem;
  }

  .text1, .text2, .text3, .text4{
    padding: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-primario);
  }

  .text2{
    width: 100%;
  }

  .text2 p, .text1 p, .text3 p, .text4 p{
    color: white !important;
    width: 100%;
  }

  .loaderContainer{
    height: 80%;
  }
}

.creaTuDemo{
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: .7rem;
}

.creaTuDemo p{
  font-size: 1rem;
  font-weight: 400;
  color: var(--color-primario);
  width: 70%;
}

.creaTuDemo h2{
  font-size: 1rem;
  font-weight: 600;
  color: var(--color-primario);
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #333;
  font-family: Arial, sans-serif;
}

.checkboxContainer input[type="checkbox"] {
  display: none;
}

.checkboxLabel::before {
  content: '';
  display: inline-block;
  width: 1rem; 
  height: 1rem;
  border: 1.5px solid var(--color-primario);
  border-radius: 4px;  
  margin-right: 0.5rem;
  vertical-align: middle;
  cursor: pointer;
}

.checkboxContainer input[type="checkbox"]:checked + .checkboxLabel::before { 
  border-color: var(--color-primario); 
  content: '✓'; 
  color: var(--color-secundario); 
  font-size: 0.8rem;
  text-align: center;
  line-height: 1rem;
}

.checkboxLabel{
  line-height: 22px;
  user-select: none;
}

.privacyLink {
  font-style: italic;
  color: var(--color-primario); /* Color del texto del enlace */
  text-decoration: none; /* Sin subrayado */
}

.privacyLink:hover {
  text-decoration: underline; /* Subrayado al pasar el ratón */
}

.wordCounter{
  color: rgba(0, 0, 0, 0.258);
  margin-bottom: -2rem;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* trasicion fadein */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}